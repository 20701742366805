import imgT1 from "../assets/image/jpg/agency/masonry/transportation-shop.jpg";
import imgT2 from "../assets/image/jpg/agency/masonry/PdPmockup2.jpg";
import imgT3 from "../assets/image/jpg/agency/masonry/happy-pets.jpg";
import imgT4 from "../assets/image/jpg/agency/masonry/tech-blog.jpg";
import imgT5 from "../assets/image/jpg/agency/masonry/note-taker.jpg";
import imgT6 from "../assets/image/jpg/agency/masonry/password-generator.jpg";
import imgT7 from "../assets/image/jpg/agency/masonry/work-day-scheduler.jpg";
export const designWorks1 = [
  {
    brand: `Transportation Shop`,
    slug: `Transportation-Shop`,
    title: `Enjoy your transportation by booking on-line.`,
    categories: ["web development"],
    thumbnail: imgT1,
  link : "/transportation-shop-portfolio-details-new"
},
];


1/*
{
    brand: `Pata De Perro`,
    slug: `Pata-De-Perro`,
    title: `Enjoy your vacation activities by booking on-line.`,
    categories: ["web development contribution"],
    thumbnail: imgT2,
  link : "/pata-de-perro-portfolio-details"
},
  {
    brand: `Happy Pets`,
    slug: `Happy-Pets`,
    title: `A veterinarian as the owner of a pet shelter, would like to help rescuers with abandoned or found pets.`,
    categories: [`web development contribution`],
    thumbnail: imgT3,
  link : "/happy-pets-portfolio-details"
},
  {
    brand: `Tech Blog`,
    slug: `Tech-Blog`,
    title: `CMS-style blog site similar to a Wordpress site, where developers can publish their blog posts and comment on other developers’ posts as well.`,
    categories: [`web development`],
    thumbnail: imgT4,
  link : "/tech-blog-portfolio-details"
},
  {
    brand: `Note Taker`,
    slug: `Note-Taker`,
    title: `Application called Note Taker that can be used to write and save notes with Express JS.`,
    categories: [ `web development`],
    thumbnail: imgT5,
  link : "/note-taker-portfolio-details"
},
  {
    brand: `Password Generator`,
    slug: `Password-Generator`,
    title: `The goal of this project is to create a strong password that provides greater security.`,
    categories: [ `web development`],
    thumbnail: imgT6,
  link : "/password-generator-portfolio-details"
},
  {
    brand: `Work Day Scheduler`,
    slug: `Work-Day-Scheduler`,
    title: `Simple calendar application that allows a user to save events for each hour of the day.`,
    categories: [`web-development`],
    thumbnail: imgT7,
  link : "/work-day-scheduler-portfolio-details"
},*/